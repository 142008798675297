module.exports = {
    name: 'uplift you',
    logo: 'https://img.genflow.com/leana/checkout/logo4.png',
    vendorUUID: '001dcbaf-4985-411f-a0d5-f2c4a83a6d00',
    metaData: {
        title: 'uplift you',
        description: 'Get stronger with Leana',
        author: 'GENFLOW',
        url: 'https://www.upliftyou.com/',
        image: 'https://img.genflow.com/leana/checkout/logo4.png',
    },
    theme: {
        colors: { // Note these need to be copied into $theme-colors in src/styles/global.scss
            primary: '#1B202C',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://upliftyou.com/terms-conditions',
        privacy: 'https://upliftyou.com/privacy-policy',
        support: 'https://upliftyou.zendesk.com/hc/en-gb',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/lift-with-leana/id1635590575',
        android: 'https://play.google.com/store/apps/details?id=com.liftwithleana.mobile',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
            showRegisterInstructions: true,
            registerInstructions: [
                'Sign up to uplift you by entering your email address and password below',
                'Select which subscription you want, and complete your purchase on the website',
                'Go to the app store and download uplift you',
                'Enter the email and password you used to purchase the app, to get access to your subscription',
            ],
            registerImage: 'https://d2ztsos3nab125.cloudfront.net/001dcbaf-4985-411f-a0d5-f2c4a83a6d00/originals/checoutImage.png',
            showTitle: false,

        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            // coupon: 'BZEG2vIB',
            // label: 'SAVE 50%',
        }, {
            title: '3 Months',
            product: '3month',
            // coupon: 'FjD5ZczA',
            // label: 'SAVE 50%',
        }, {
            title: '12 Months',
            product: '12month',
            // coupon: 'Fwkvu3QX',
            // label: 'SAVE 50%',
        },
    ],
    saleProducts: [
    ],
    offers: [
        {
            id: 'yt',
            products: [
                {
                    title: '1 Month',
                    product: '1month',
                    coupon: 'BZEG2vIB',
                    label: 'SAVE 50%',
                }, {
                    title: '3 Months',
                    product: '3month',
                    coupon: 'FjD5ZczA',
                    label: 'SAVE 50%',
                }, {
                    title: '12 Months',
                    product: '12month',
                    coupon: 'Fwkvu3QX',
                    label: 'SAVE 50%',
                },
            ],
        },
        {
            id: 'dubai',
            products: [
                {
                    title: '3 Month',
                    product: '3monthextendedtrial',
                    // coupon: 'BZEG2vIB',
                    // label: 'SAVE 50%',
                },
            ]
        }
    ],
    currencies: {
        available: [
            {
                code: 'GBP',
                icon: '🇬🇧',
            },
            {
                code: 'USD',
                icon: '🇺🇸',
            },
            {
                code: 'EUR',
                icon: '🇪🇺',
            },
            {
                code: 'MXN',
                icon: '🇲🇽',
            },
            {
                code: 'BRL',
                icon: '🇧🇷',
            },
            {
                code: 'COP',
                icon: '🇨🇴',
            },
        ],
        default: 'USD',
    },
};
